"use strict";

/**
 * Created by Thiago Lima on 20/02/2020.
 */

(function () {
  'use strict';

  var aggregateMetricsLanguage = {
    header: {
      initial: 'Métricas Agregadas do',
      channel: 'Google Meu Negócio'
    },
    overview: {
      header: {
        title: 'Visão geral das Avaliações'
      },
      table: {
        header: {
          rating: 'Nota',
          amountReviews: 'Núm. Avaliações',
          percentage: 'Porcentagem'
        },
        body: {
          positive: 'Positivo',
          neutral: 'Neutro',
          negative: 'Negativo',
          total: 'Total'
        },
        tooltip: {
          positive: 'Notas 4 e 5',
          neutral: 'Nota 3',
          negative: 'Notas 1 e 2'
        }
      }
    },
    positiveUnit: {
      title: 'Unidade com mais avaliações'
    },
    negativeUnit: {
      title: 'Unidade com mais avaliações negativas'
    },
    errorMessage: 'Não encontramos dados suficientes. Tente outros filtros.'
  };
  window.moduleExport({
    aggregateMetricsLanguage: aggregateMetricsLanguage
  }, typeof module !== 'undefined' && module);
})();